import SVG from 'svg.js';
// import './os-interactive-map.style.css';

function interactiveMapSetup(state) {
  const props = {...state};

  // New Properties
  props.activeCountries = props.countryData.map((a) => a.alpha_3_code);
  props.mapContainer = document.getElementById('interactiveMap');
  props.currentlyHovered = '';
  props.countries = SVG.get('countries');

  // New Methods
  props.getCountry = (req) =>
    props.countryData.find((country) => country.alpha_3_code === req) ||
    false;
  props.isCountryActive = (alphaCode) =>
    props.activeCountries.includes(alphaCode) || false;

  return props;
}

function createLabel(countryId, props) {
  const {marker, country_flag, name} = props.getCountry(countryId);
  const markerBounds = marker.bbox();
  const markerPos = {
    x: marker.x() + markerBounds.w,
    y: marker.y() + markerBounds.h / 2,
  };
  const mapBounds = props.countries.bbox();

  const posX = (markerPos.x / mapBounds.w) * 100;
  const posY = (markerPos.y / mapBounds.h) * 100;

  const label = document.createElement('div');
  label.id = `${countryId}MarkerLabel`;
  label.className = 'label';
  label.style.cssText = `left: ${posX}%; top: ${posY}%;`;
  label.innerHTML = `${country_flag.img} <span>${name}</span>`;

  props.mapContainer.append(label);

  return label;
}

function positionMarker(marker, pos, offset) {
  const markerBounds = marker.bbox();
  marker
    .transform({
      x: pos.cx - markerBounds.w / 2,
      y: pos.cy - markerBounds.h,
    })
    .transform({x: offset.x, y: offset.y, relative: true});

  return marker;
}

function createMarker(
  svg,
  id,
  pos = {cx: 0, cy: 0},
  offset = {x: 0, y: 0},
) {
  let marker = svg.group().id(`${id}Marker`).toggleClass('marker');

  // MePin
  marker
    .path(
      'M5.44,15.5C.91,8.87,0,8.2,0,5.75a6.06,6.06,0,0,1,12.1,0c0,2.45-.84,3.2-5.43,9.75a.74.74,0,0,1-1,.22A.63.63,0,0,1,5.44,15.5Zm.62-7.22A2.53,2.53,0,1,0,3.53,5.75h0A2.51,2.51,0,0,0,6,8.28Z',
    )
    .toggleClass('me-pin');

  // position the marker on the country
  marker = positionMarker(marker, pos, offset);

  return marker;
}

function init(props) {
  const scope = {...props};

  function callback() {
    const id = this.attr('id');

    if (scope.isCountryActive(id)) {
      this.toggleClass(scope.countryActiveClass);

      // Marker
      const {cx, cy} = this.bbox();
      const country = scope.getCountry(id);

      // save refrence to maker
      country.marker = createMarker(
        scope.countries,
        id,
        {cx, cy},
        {x: country.marker_offset.x, y: country.marker_offset.y},
      );

      // Events
      this.on('mouseover', countryMouseOver, {self: this, props: scope});
      this.on('mouseout', countryMouseOut, {self: this, props: scope});

      // Is the map interactive?
      if (scope.isInteractive) {
        // country.label = createLabel(id, this.rbox());
        this.on('click', countryClick, {self: this, props: scope});
      }
    } else {
      this.toggleClass(scope.countryClass);
    }
  }

  // Linter made me do this
  // .each is an SVG method
  scope.countries.each(callback);

  return scope;
}

function countryMouseOver() {
  const {self} = this;
  const {props} = this;
  const country = props.getCountry(self.attr('id'));

  const {marker} = props.getCountry(self.attr('id'));

  // Create the label if it doesn't exist
  if (!country.markerLabel) {
    country.markerLabel = createLabel(self.attr('id'), props);
  }

  marker.finish().animate(150, '<>').transform({
    scale: 1.6,
  });

  country.markerLabel.classList.add('label__show');
}

function countryMouseOut() {
  const {self, props} = this;
  const {marker, markerLabel} = props.getCountry(self.attr('id'));

  markerLabel.classList.remove('label__show');
  marker.animate(50, '<>').transform({
    scale: 1,
  });
}

function countryClick() {
  const {self, props} = this;
  const {permalink} = props.getCountry(self.attr('id'));

  window.location.href = permalink;
}

export default function interactiveMap(state) {
  if (!state) {
    console.error('Interactive Map: No State Provided');
    return;
  }

  // Setup our state
  let currentProps = interactiveMapSetup(state);

  // Initialize Countries
  init(currentProps);
}

interactiveMap(window.interactiveMapState);
